<template>
  <div class="list-bg-box">
    <div class="list-box-title">证书查询</div>
    <div class="info-box">
      <div class="info-title">日期：</div>
      <el-date-picker
        v-model="dateVal"
        type="daterange"
        @change="onChange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </div>
    <div class="info-box">
      <div class="info-title">类别：</div>
      <div class="classifyList">
        <div
          :class="['classify-item', item.name == classifyName ? 'active' : '']"
          v-for="(item, index) in navList"
          :key="index"
          @click="changeClassify(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="info-box">
      <div class="info-label p-r-300">教师信息</div>
      <div class="info-label p-r-56">发证日期</div>
      <div class="info-label p-r-40">类型</div>
      <div class="info-label p-r-40">级别</div>
      <div class="info-label p-r-62">证书编号</div>
      <div class="info-label p-r-300">题目</div>
      <div class="info-label">颁发单位</div>
    </div>
    <div class="list" v-if="dataList.length > 0">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <div class="item-info">
          <!-- <div class="info-logo">
            <img class="logo" :src="item.teacher_img" alt="" />
          </div>
          <div class="info-right">
            <div class="right-top">
              <div class="top-name">{{ item.teacher_name }}</div>
              <div class="top-sex">性别：{{ item.sex }}</div>
            </div>
            <div class="right-zw">任教学科：{{ item.subject }}</div>
            <div class="right-remark">个人简介：{{ item.teacher_remark }}</div>
            <div class="right-school">所属院校：{{ item.school }}</div>
          </div> -->
          <div class="right-name">教师姓名：{{ item.realname }}</div>
        </div>
        <div class="getTime">{{ item.obtain_date }}</div>
        <div class="typeName">{{ item.category }}</div>
        <div class="jbName">{{ item.level }}</div>
        <div class="certifiacteNumber">{{ item.code }}</div>
        <!-- <div class="btn-box"> -->
        <div class="btn-name">{{ item.name }}</div>
        <!-- <div
            :class="['btn', item.fk_cert_id > 0 ? 'show' : '']"
            @click="openCertificate(item.fk_cert_id)"
          >
            点击查看证书
          </div> -->
        <!-- </div> -->
        <div class="dwName">{{ item.award_unit }}</div>
      </div>
    </div>
    <div class="kong" v-else>
      <el-empty
        image="https://eduapi.114kaili.com/Content/images/no_data.png"
        :image-size="303"
        description="暂无数据"
      ></el-empty>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="800px">
      <div class="tc-box" v-html="CertInfo.content"></div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      dateVal: "",
      CertInfo: "",
      classifyName: "全部",
      navList: [],
    };
  },
  mounted() {
    api
      .Category({
        fk_business_id: this.$store.state.businessId,
        type: 10,
      })
      .then((res) => {
        this.navList = res.data;
      });
  },
  methods: {
    changeClassify(item) {
      this.classifyName = item.name;
      this.$emit("onClassify", item.name);
    },
    onChange() {
      let start_date = this.formatData(this.dateVal[0], "YYYY-MM-DD");
      let end_date = this.formatData(this.dateVal[1], "YYYY-MM-DD");
      let timeObj = {
        start_date,
        end_date,
      };
      this.$emit("onTime", timeObj);
    },
    openCertificate(id) {
      api
        .CertDetail({
          fk_cert_id: id,
        })
        .then((res) => {
          this.CertInfo = res.data;
          this.dialogVisible = true;
        });
    },
    // openDetail(id) {
    //   this.$router.push({
    //     name: "cert_detail",
    //     query: {
    //       id,
    //     },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 100% !important;
  border: 1px solid #dcdfe6 !important;
}
.list-bg-box {
  width: 100%;
  padding: 26px 30px 0;
  background-color: #fff;
  border-radius: 10px;
  .list-box-title {
    width: 100%;
    font-size: 22px;
    color: #000000;
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f1f1;
  }
  .info-box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #f1f1f1;
    .info-title {
      font-size: 14px;
      color: #000000;
      padding-right: 20px;
    }
    .info-label {
      font-size: 16px;
      color: #000000;
      &.p-r-300 {
        padding-right: 300px;
      }
      &.p-r-56 {
        padding-right: 56px;
      }
      &.p-r-40 {
        padding-right: 40px;
      }

      &.p-r-62 {
        padding-right: 62px;
      }
    }
    .classifyList {
      flex: 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      .classify-item {
        font-size: 14px;
        color: #000000;
        padding: 10px;
        cursor: pointer;
        margin-left: 10px;
        &.active {
          background: #0086ff;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
  }
  .list {
    width: 100%;
    .item {
      width: 100%;
      padding: 34px 0;
      border-bottom: 1px solid #f1f1f1;
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;
      .item-info {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 364px;
        // flex: 1;
        .right-name {
          color: #0086ff;
        }
        // .info-logo {
        //   width: 132px;
        //   height: 132px;
        //   border-radius: 10px;
        //   overflow: hidden;
        //   margin-right: 20px;
        //   .logo {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        // .info-right {
        //   display: flex;
        //   flex-direction: column;
        //   .right-top {
        //     display: flex;
        //     align-items: center;
        //     padding-bottom: 20px;
        //     .top-name {
        //       font-size: 18px;
        //       color: #1d2088;
        //       padding-right: 10px;
        //     }
        //     .top-sex {
        //       font-size: 18px;
        //       color: #999999;
        //     }
        //   }
        //   .right-zw {
        //     font-size: 14px;
        //     color: #666666;
        //     padding-bottom: 10px;
        //   }
        //   .right-remark {
        //     font-size: 14px;
        //     color: #666666;
        //     width: 252px;
        //     padding-bottom: 10px;
        //   }
        //   .right-school {
        //     font-size: 14px;
        //     color: #666666;
        //     padding-bottom: 10px;
        //   }
        // }
      }
      .getTime {
        width: 106px;
        // text-align: center;
      }
      .typeName {
        width: 60px;
        text-align: center;
      }
      .jbName {
        padding-left: 16px;
        width: 50px;
        text-align: center;
      }
      .certifiacteNumber {
        width: 100px;
        padding-left: 16px;
        text-align: center;
        overflow: hidden; // 溢出部分隐藏
        white-space: nowrap; // 文字不换行
        text-overflow: ellipsis; // 显示省略号
      }
      // .btn-box {
      //   display: flex;
      //   flex-direction: column;
      //   // position: relative;
      //   // top: 18px;
      //   width: 300px;
      //   padding-left: 50px;
      //   overflow: hidden; // 溢出部分隐藏
      //   white-space: nowrap; // 文字不换行
      text-overflow: ellipsis; // 显示省略号
      .btn-name {
        // padding-bottom: 14px;
        padding-left: 40px;
        flex: 1;
      }
      //   .btn {
      //     width: 90px;
      //     height: 26px;
      //     line-height: 26px;
      //     text-align: center;
      //     background: #0086ff;
      //     border-radius: 17px;
      //     font-size: 12px;
      //     color: #ffffff;
      //     cursor: pointer;
      //     opacity: 0;
      //     &.show {
      //       opacity: 1;
      //     }
      //   }
      // }
      .dwName {
        width: 130px;
        text-align: center;
      }
    }
  }
}
.kong {
  padding: 100px 0;
  width: 100%;
  margin: 80px auto 0;
  background-color: #fff;
  border-radius: 10px;
}
.tc-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  overflow-y: auto;
  .img {
    width: 600px;
    height: 600px;
  }
}
</style>
