<template>
  <div class="box">
    <div class="content-banner">
      <img class="cb-img" src="@/assets/certificate/banner1.png" alt="" />
      <div class="search">
        <div class="input-box">
          <el-input
            v-model="searchForm.keywords"
            placeholder="请输入教师姓名、学校名称关键字..."
            :clearable="true"
            @clear="onSearch"
          ></el-input>
        </div>
        <div class="search-btn" @click="onSearch">搜索</div>
      </div>
    </div>
    <div class="list-box">
      <data-list
        :dataList="dataList"
        @onClassify="onClassify"
        @onTime="onTime"
      />
    </div>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :background="true"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
        :page-size="dataListParams.pageSize"
        layout="total, prev, pager, next"
        :total="dataListParams.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import dataList from "./components/dataList";
import tableMinxin from "@/mixins/tableMinxin.js";
export default {
  mixins: [tableMinxin],
  components: {
    dataList,
  },
  data() {
    return {
      searchForm: {
        keywords: "",
        start_date: "",
        end_date: "",
        category: "",
      },
    };
  },
  mounted() {
    if (this.$route.params.name) {
      this.searchForm.keywords = this.$route.params.name;
    }
    this.onSearch();
  },
  methods: {
    onSearch() {
      this._init_data("Teacher", {
        ...this.searchForm,
        fk_user_id: this.userInfo.fk_user_id || 0,
        fk_business_id: this.$store.state.businessId,
      });
    },
    onClassify(val) {
      this.searchForm.category = val == "全部" ? "" : val;
      this.onSearch();
    },
    onTime(val) {
      this.searchForm.start_date = val.start_date;
      this.searchForm.end_date = val.end_date;
      this.onSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 50px;
  font-size: 15px;
  border: none;
}
.content-banner {
  padding-top: 30px;
  width: 603px;
  .cb-img {
    width: 60%;
    height: auto;
  }
  .search {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 25px;
    .input-box {
      flex: 1;
      height: 100%;
      border-radius: 10px 0 0 10px;
      overflow: hidden;
    }
    .search-btn {
      width: 115px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0086ff;
      color: #fff;
      font-size: 19px;
      border-radius: 0px 10px 10px 0px;
      cursor: pointer;
    }
  }
}
.list-box {
  width: 100%;
  padding-top: 52px;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 58px;
  padding-bottom: 70px;
}
</style>
